import '../stylesheets/main.scss';

// Use what-input to track input method
import 'what-input';
import 'htmx.org';
// htmx initialization
// See https://htmx.org/docs/#webpack
import './init-htmx';
import 'htmx.org/dist/ext/multi-swap';

import 'core-js/modules/es.symbol';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm';

import domready from 'domready';
import smoothscroll from 'smoothscroll-polyfill';

import '../components/svg-sprite/javascripts';

import enableAnimations from './animation/enableAnimations';
import fixAnchorAnimations from './animation/fixAnchorAnimations';

import initAccordion from '../components/accordion/javascripts';
import initBlockMedia from '../components/block-media/javascripts';
// import initBrowserCheck from '../components/browser-check/javascript';
import initContact from '../components/contact/javascripts';
import initChart from '../components/chart/javascripts';
// import initDebugGrid from '../components/debug-grid/javascripts';
import initFaqContainer from '../components/faq-container/javascripts';
import initForm from '../components/form/javascripts';
import initFixingRates from '../components/fixing-rates/javascripts';
import initImage from '../components/image/javascripts';
import initInvestmentAdvice from '../components/investment-advice/javascripts';
import initInvestorProfile from '../components/investor-profile/javascripts';
import initInxmail from '../components/inxmail/javascripts';
import initLocation from '../components/location/javascripts';
import initModal from '../components/modal/javascripts';
import initMenubar from '../components/menubar/javascripts';
import initNavigation from '../components/navigation/javascripts';
import initNotification from '../components/notification/javascripts';
import initSearch from '../components/search/javascripts';
import initStageHome from '../components/stage-home/javascripts';
import initTabs from '../components/tabs/javascripts';
import initTombstones from '../components/tombstones/javascripts';
import initToolTip from '../components/tooltip/javascripts';
import initTopBtn from '../components/top-btn/javascripts';
import initVideo from '../components/video/javascripts';
import initTeaserRowTracking from '../components/teaser-row/teaserRowTracking';
import initFooterTracking from '../components/footer/footerTracking';
// initialise smoothscroll polyfill!
smoothscroll.polyfill();

function enhancedDomReady(init) {
  domready(init);
  // Eventlistener for storybook reload event
  window.addEventListener('reload', init); // can cause several inits in storybook
}

enhancedDomReady(() => {
  initAccordion();
  initBlockMedia();
  // initBrowserCheck();
  initContact();
  initChart();
  // initDebugGrid();
  initFaqContainer();
  initForm();
  initFixingRates();
  initImage();
  initInvestmentAdvice();
  initInvestorProfile();
  initInxmail();
  initLocation();
  initMenubar();
  initModal();
  initNavigation();
  initNotification();
  initSearch();
  initStageHome();
  initTabs();
  initTombstones();
  initToolTip();
  initTopBtn();
  initVideo();
  initTeaserRowTracking();
  initFooterTracking();
  enableAnimations();
  fixAnchorAnimations();
});

// All links with target="_blank" attribute should have an attribute rel="noopener". This has performance and security reasons https://developers.google.com/web/tools/lighthouse/audits/noopener
const externalLinks = document.querySelectorAll("[target='_blank']");
if (externalLinks) {
  externalLinks.forEach((link) => {
    link.setAttribute('rel', 'noopener');
  });
}

const lightbox = new PhotoSwipeLightbox({
  gallery: 'body',
  children: 'a[data-pswp]',
  showHideAnimationType: 'none',
  zoomAnimationDuration: false,
  pswpModule: () => import('photoswipe')
});

lightbox.init();
